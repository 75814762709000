<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog"
      persistent
      width="1000"
      hide-overlay
      light
      class="ml-6"
    >
      <v-card>
        <v-card>
          <v-toolbar color="white" dark>
            <v-btn @click="backToSections">
              <v-icon> mdi-arrow-left</v-icon>
              BACK
            </v-btn>
            <v-card-title style="color: black">
              {{ sectionLines.title }}
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="blue" @click="saveSectionReview(sectionLines.code)">
              <v-icon></v-icon>
              SAVE
            </v-btn>
          </v-toolbar>
        </v-card>
        <v-row no-gutters>
          <v-col cols="8">
            <v-card flat>
              <v-card-text>
                <v-list three-line>
                  <template
                    v-for="item in sectionLines.applicationSectionLines"
                  >
                    <v-list-item :key="item.code">
                      <v-list-item-avatar color="blue">
                        <span>{{ item.section + "" + item.code }}</span>
                      </v-list-item-avatar>

                      <div>
                        <small>
                          {{ item.instruction }}
                        </small>
                        <p class="mt-3">
                          {{ item.contents }}
                        </p>
                      </div>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>Section Attachments</v-card-title>
              <v-data-table
                :headers="headers"
                :items="documents"
                :loading="documentLoading"
                loading-text="Loading... Please stand by"
              >
                <!--eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    @click="viewDocument(item)"
                    class="mr-4"
                    color="green"
                    v-if="item.fileExtension === 'pdf'"
                  >
                    <v-icon class="mr-2">mdi-eye</v-icon>
                    View
                  </v-btn>
                  <v-btn
                    @click="downloadDocument(item)"
                    class="mr-4"
                    color="blue"
                  >
                    <v-icon class="mr-2">mdi-download</v-icon>
                    Download
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        label="Comment*"
                        rows="4"
                        row-height="30"
                        v-model="comment"
                        @change="cacheUserEditPerSection"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row v-if="!disableMarks">
                    <v-col cols="12">
                      <v-textarea
                        :label="`Marks (maximum: ${sectionLines.maxMarksAwarded}%)`"
                        :disabled="disableMarks"
                        rows="1"
                        row-height="30"
                        v-model="marks"
                        :value="mark"
                        @input="assignedMarks"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <small class="warningText">{{
                    assignedMarks
                      ? "Can not be more than maximum allocated marks!"
                      : errorMessage
                  }}</small>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <ViewDocument
      :dialog="viewDoc"
      :doc="documentToView"
      @modDialog="closeViewDocdialog"
    />
  </v-container>
</template>
<script>
import RouterMixin from "../../../router/RouterMixin";
import { appName } from "@/environment";
import { FileMixin } from "@/mixins";
import ViewDocument from "./ViewDocuments.vue";

export default {
  name: "ApplicationSections",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    sectionLines: {
      type: Object,
      required: true,
    },
    savedSectionReview: {
      // type: Object,
      required: true,
    },
    mark: {
      required: true,
    },
    comments: {
      type: String,
      required: true,
    },
    documents: {
      default: () => [],
      required: true,
      type: Array,
    },
    loader: {
      type: Boolean,
      required: true,
    },
  },
  components: { ViewDocument },
  mixins: [RouterMixin, FileMixin],
  data() {
    return {
      errorMessage: "",
      viewDoc: false,
      documentToView: {},
    };
  },
  computed: {
    marks: {
      get() {
        return this.mark === null ? this.savedSectionReview?.marks : this.mark;
      },
      set(value) {
        this.$emit("updateMark", value);
      },
    },
    comment: {
      get() {
        return this.comments === ""
          ? this.savedSectionReview?.comment
          : this.comments;
      },
      set(value) {
        this.$emit("updateComment", value);
      },
    },
    user() {
      return JSON.parse(localStorage.getItem(`${appName}_user`) || "[]");
    },
    disableMarks() {
      return this.sectionLines.maxMarksAwarded === 0 ? true : false;
    },
    assignedMarks() {
      return Number(this.marks) > this.sectionLines.maxMarksAwarded;
    },
    documentLoading() {
      return this.loader;
    },
    headers() {
      return [
        {
          text: "Attachment Name",
          value: "fileName",
          sortable: true,
        },
        {
          text: "Attachment Type",
          value: "fileExtension",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    cacheUserEditPerSection() {
      let localStorageKey = `${this.decodeRoute(
        this.$route.params.applicationId
      )}.${this.sectionLines.code}`;
      localStorage.setItem(localStorageKey, this.comment);
    },
    closeViewDocdialog(val) {
      this.viewDoc = val;
    },
    viewDocument(item) {
      this.documentToView = item;
      this.viewDoc = true;
    },
    backToSections() {
      this.closeModal();
    },
    reviewsValidate() {
      const numRegex = /^\d+$/;
      if (this.assignedMarks) return "More than Allowed Marks";
      if (this.comment === "" && !this.disableMarks && this.marks === null) {
        return (this.errorMessage = "All the fields are required!");
      }
      if (!this.disableMarks && !numRegex.test(this.marks)) {
        return (this.errorMessage = " Marks must be a number!");
      }
      if (this.comment === "") return "Must have a comment";
      return;
    },
    saveSectionReview(code) {
      const error = this.reviewsValidate();
      if (error !== undefined) return;
      const emitObject = {
        section: code,
        comment: this.comment,
        ...(!this.disableMarks ? { marks: this.marks } : {}),
      };
      this.$emit("save", emitObject);
      // this.$store.dispatch("Grants/getApplication", {
      //   no: this.decodeRoute(this.$route.params.applicationId),
      // });
      // this.$router.push({
      //   name: "reviewSingleApplication",
      //   params: {
      //     applicationId: this.encodeRoute(
      //       this.decodeRoute(this.$route.params.applicationId)
      //     ),
      //   },
      // });
      this.closeModal();
    },
    closeModal() {
      this.$emit("setDialog", false);
    },
  },
};
</script>
<style scoped>
.warningText {
  color: red;
}
</style>
