import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#43a047",
        secondary: "#23282d",
        accent: "#385F73",
        error: colors.red.accent3,
        background: colors.grey.lighten4,
        aside: "#43a047",
        info: colors.teal.darken1,
      },
      dark: {
        background: colors.grey.darken3,
        primary: "#e01c13",
        secondary: "#23282d",
      },
    },
  },

  icons: {
    iconfont: "mdi",
  },
};

export default new Vuetify(opts);
