import { crud } from "@/mixins/urlMixin";

export default {
  stage: crud("application/stage"),
  batch: crud("application/batch"),
  applications: crud("application"),
  profile: crud("application/profile"),
  application: crud("application/profile/details"),
  certifications: crud("application/profile/certification"),
  workExperience: crud("application/profile/experience"),
  membershipInstitution: crud("application/profile/membership-institution"),
  specialization: crud("application/profile/specialization"),
  referee: crud("application/profile/referee"),
  attachment: crud("application/profile/attachment"),

  forward: "application/approval/forward",
  rewind: "application/approval/rewind",
  hold: "application/approval/hold",
  decline: "application/approval/decline",
  approveAll: "application/approval/approve-all",

  boardPaper: "application/report/board-paper",
  getReport: "application/report/board-paper",
  assignReviewer: "application/assign-reviewer",

  uploadBooklet: "application/booklet/upload",
  deferApplication: "application/booklet/defer",
  getBooklet: "application/booklet/getBooklet",
  delBooklet: "application/booklet/getBooklet",
  universityResponse: "application/university-response",
  responseDocument: "application/get-university-response",
  notify: "application/notify-university",
};
