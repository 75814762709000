<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-form v-model="isValid" ref="attachForm">
          <v-card-title> Upload Booklet </v-card-title>

          <v-alert
            outlined
            class="pa-5"
            dense
            v-if="$store.getters['Application/alert'].status !== ''"
            border="left"
            :type="
              $store.getters['Application/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
          >
            {{ $store.getters["Application/alert"].message }}
          </v-alert>

          <v-col cols="12">
            <v-file-input
              accept="application/pdf"
              :rules="rules.Field"
              dense
              prepend-icon="mdi-paperclip"
              outlined
              persistent-placeholder
              placeholder="Attach Document"
              @change="uploadFile"
              v-model="Doc"
              ><template v-slot:label>
                <div>
                  Attachment
                  <span class="red--text">*</span>
                </div>
              </template></v-file-input
            >
          </v-col>
        </v-form>
        <v-card-actions class="pb-7 mr-6 mt-n3">
          <v-spacer />
          <v-btn class="mx-3" v-on:click="setDialog = false" color="error" text>
            Cancel
          </v-btn>
          <v-btn @click="Save()" outlined color="secondary"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { AuthService } from "@/modules/auth";
import helper from "../../../utils/helpers/helper";
export default {
  name: "uploadBookletCard",
  props: {
    uploadData: Object,
    dialog: Boolean,
  },

  data: function () {
    return {
      isValid: false,
      Doc: undefined,
      formData: {
        applicationCode: "",
        attachment: "",
      },
    };
  },

  computed: {
    user() {
      return AuthService.user;
    },
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
        this.$router.push({
          name: "Application List",
        });
      },
    },
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
      };
    },
    booklet() {
      return this.$store.getters["Application/booklet"];
    },
  },

  methods: {
    Save: function () {
      if (!this.isValid) {
        this.$refs.attachForm.validate();
      } else {
        this.formData.applicationCode = this.uploadData.Code;
        this.$store.dispatch(
          "Application/uploadBooklet",
          helper.prepareFormData({ ...this.formData })
        );
        this.setDialog = false;
      }
    },
    uploadFile: async function (value) {
      if (value) {
        this.formData.attachment = value;
      }
    },

    pageCount: function (value) {
      if (!value) return 0;
      const bin = atob(value);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
  },
};
</script>
