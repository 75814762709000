export default {
  methods: {
    reviewRejection(obj) {
      this.$store.dispatch("Grants/rejectingReview", obj);
      this.$router.push({
        name: "grantList",
      });
    },
  },
};
