import constants from "./constants";
import { request } from "@/service";
import { error, success } from "@/plugins/snack/snack";
export default {
  namespaced: true,
  state: {
    assignedApplication: [],
    assignedApplicationInprogress: [],
    application: [],
    awardedMarks: {},
    alert: {
      status: "",
      message: "",
    },
    error: null,
    loading: false,
    reviewerScores: [],
    applicationAttachments: [],
    lineAttachments: [],
    applicationForOfflineReview: "",
    retrievedCacheApplicationInformation: [],
    activeDownload: "",
    grantListTableItems: [],
  },
  mutations: {
    MUTATE: (state, { type, data }) => (state[type] = data),
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    grantGetters: (state) => (payload) => state[payload.type],
  },
  actions: {
    setLoading({ commit }, payload) {
      commit("MUTATE", { type: "loading" }, payload);
    },
    getGrantsToReview({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });
      request("get", constants.grants(params))
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          commit("MUTATE", {
            type: "assignedApplication",
            data: response.data,
          });
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading" }, false);
          error("We ran into an error while retrieving data");
          commit("SET_ALERT", {
            status: "err",
            message: "We ran into an error while retrieving data",
          });
        });
    },
    getApplication({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });

      request("get", constants.application(params))
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });

          commit("MUTATE", { type: "application", data: response.data });
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading", data: false });

          error("We ran into an error while retrieving data");
          commit("SET_ALERT", {
            status: "err",
            message: "We ran into an error while retrieving data",
          });
        });
    },
    declareConflict({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });

      request("patch", constants.conflict, payload)
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          success("Submitted successfully!");

          commit("MUTATE", {
            type: "assignedApplication",
            data: response.data,
          });
          dispatch("getGrantsToReview");
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading", data: false });
          error(
            "We ran into an error while submiting your conflict, please try again!"
          );
          commit("SET_ALERT", {
            status: "err",
            message:
              "We ran into an error while submiting your conflict, please try again!",
          });
        });
    },
    saveMarks({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });

      request("post", constants.saveMarks, payload)
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          success("Saved successfully!");

          // commit("MUTATE", {
          //   type: "application",
          //   data: response.data,
          // });
          dispatch("reviewerScore", {
            documentNo: response.data.data[0].documentNo,
            reviewer: response.data.data[0].reviewer,
          });
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading", data: false });
          commit("MUTATE", { type: "error" }, false);

          error("We could not save your actions, please try again!");
          commit("SET_ALERT", {
            status: "err",
            message: "We could not save your actions, please try again!",
          });
        });
    },
    getApplicationAttachments({ commit }, params) {
      commit("MUTATE", { type: "error" }, false);

      request("get", constants.grantApplicationAttachments(params))
        .then((res) => {
          commit("MUTATE", { type: "error" }, false);
          commit("MUTATE", { type: "loading", data: false });
          commit("MUTATE", {
            type: "applicationAttachments",
            data: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "error" }, false);

          error(
            "We ran into an error while getting the attachments, please try again!"
          );
          commit("MUTATE", { type: "loading", data: false });
        });
    },
    updateMarks({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });

      request("patch", constants.updateMarks, payload)
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          success("Section review updated successfully!");

          // commit("MUTATE", {
          //   type: "application",
          //   data: response.data.data,
          // });
          dispatch("reviewerScore", {
            documentNo: response.data.data[0].documentNo,
            reviewer: response.data.data[0].reviewer,
          });
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading" }, false);

          error(
            "We encountered an error updating your actions, please try again!"
          );
          commit("SET_ALERT", {
            status: "err",
            message:
              "We encountered an error updating your actions, please try again!",
          });
        });
    },
    reviewerScore({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });

      request("get", constants.reviewerScore(params))
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });

          commit("MUTATE", {
            type: "reviewerScores",
            data: response.data.data,
          });
        })
        .catch((err) => {
          console.log(err);

          commit("MUTATE", { type: "loading", data: false });
          error("We could not get the scores");
        });
    },
    submitReviewedApplication({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });

      request("patch", constants.submitReviewedApplication, payload)
        .then(() => {
          commit("MUTATE", { type: "loading", data: false });
          success("Application Review submitted successfully!");

          dispatch("getGrantsToReview");
        })
        .catch((err) => {
          console.error(err);
          error(
            "We could not successfully submit your review. Please try again!"
          );

          commit("MUTATE", { type: "loading" }, false);
          commit("MUTATE", {
            type: "error",
            data: "We could not successfully submit your review. Please try again!",
          });
          commit("SET_ALERT", {
            status: "err",
            message:
              "We could not successfully submit your review. Please try again!",
          });
        });
    },
    applicationReviewInprogress({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });

      request("get", constants.editMarks(params))
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });

          commit("MUTATE", {
            type: "assignedApplicationInprogress",
            data: response.data.data,
          });
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading" }, false);

          error("Sorry, something went wrong.");
          commit("SET_ALERT", {
            status: "err",
            message: "Sorry, something went wrong.",
          });
        });
    },
    getLineAttachments({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });

      request("get", constants.lineAttachments(params))
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });

          commit("MUTATE", {
            type: "lineAttachments",
            data: response.data.data,
          });
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading" }, false);

          error(
            "Sorry, We had trouble getting this attachments. Please try again"
          );
          commit("SET_ALERT", {
            status: "err",
            message:
              "Sorry, We had trouble getting this attachments. Please try again",
          });
        });
    },
    rejectingReview({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });

      request("patch", constants.reject, payload)
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          success("Request to review proposal reject!");

          commit("MUTATE", {
            type: "assignedApplication",
            data: response.data,
          });
          dispatch("getGrantsToReview");
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading" }, false);

          error("We could not complete this request, please try again");
          commit("SET_ALERT", {
            status: "err",
            message: "We could not complete this request, please try again",
          });
        });
    },
    uploadApplicationToReviewOffline({ commit, dispatch }, data) {
      commit("MUTATE", { type: "loading", data: true });

      request("post", constants.uploadAfterOfflineReview, data)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("MUTATE", { type: "loading", data: false });
            success("Application Review submitted successfully!");
            dispatch("removeItemFromLocalStorageAndDBPersistedData", {
              documentNo: data.documentNo,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading" }, false);

          error(
            "We ran into an error trying to upload the file, please try again!"
          );
        });
    },
    downloadApplicationToReviewOffline({ commit, dispatch }, params) {
      commit("MUTATE", { type: "loading", data: true });

      const { documentName, ...paramList } = params;
      request("get", constants.downloadForOfflineReview(paramList))
        .then((res) => {
          commit("MUTATE", { type: "loading", data: false });

          commit("MUTATE", {
            type: "applicationForOfflineReview",
            data: res.data.data[0],
          });
          const cacheExportData = {
            reviewer: params.reviewer,
            documentName: documentName,
            applicationDownloaded: true,
            applicationUploaded: false,
            documentNo: params.documentNo,
          };
          commit("MUTATE", {
            type: "activeDownload",
            data: cacheExportData.documentNo,
          });
          dispatch("getLocalStorageCachedRecord", {
            reviewer: { reviewer: params.reviewer },
            applicationData: cacheExportData,
          });
        })
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading" }, false);

          error("We encountered an error downloading your review document");
          commit("SET_ALERT", {
            status: "err",
            message: "We encountered an error downloading your review document",
          });
        });
    },
    removeItemFromLocalStorageAndDBPersistedData({ commit, state }, data) {
      if (Object.entries(data).length < 1) return;
      const newList = state.retrievedCacheApplicationInformation.filter(
        (item) => {
          return item.documentNo !== data.documentNo;
        }
      );
      if (newList.length < 1) return;
      commit("MUTATE", {
        type: "retrievedCacheApplicationInformation",
        data: newList,
      });
      localStorage.setItem(
        "cacheApplicationDownload",
        JSON.stringify(state.retrievedCacheApplicationInformation)
      );
      request("post", constants.deleteCachedRecordFromDB, data)
        .then(() => {})
        .catch((err) => {
          console.error(err);
          commit("MUTATE", { type: "loading" }, false);
          error(
            "Sorry, We experienced an error while processing a related action, this does not affect you."
          );
        });
    },
    setLocalStorageCachedRecord({ state }, data) {
      if (Object.entries(data).length < 1) return;
      const recordExist = state.retrievedCacheApplicationInformation.find(
        (record) => {
          return record.documentNo === data.documentNo;
        }
      );
      if (recordExist === undefined) {
        state.retrievedCacheApplicationInformation = [
          ...state.retrievedCacheApplicationInformation,
          data,
        ];
        localStorage.setItem(
          "cacheApplicationDownload",
          JSON.stringify(state.retrievedCacheApplicationInformation)
        );
        return;
      }
      localStorage.setItem(
        "cacheApplicationDownload",
        JSON.stringify(state.retrievedCacheApplicationInformation)
      );
    },
    getLocalStorageCachedRecord({ commit, dispatch }, data) {
      const getCacheApplicationDownload = JSON.parse(
        localStorage.getItem("cacheApplicationDownload") || "[]"
      );
      if (getCacheApplicationDownload.length < 1) {
        dispatch("retrievePersistedUserCacheOnDB", data);
        return;
      }
      commit("MUTATE", {
        type: "retrievedCacheApplicationInformation",
        data: getCacheApplicationDownload,
      });
      dispatch("setLocalStorageCachedRecord", data.applicationData);
    },
    retrievePersistedUserCacheOnDB({ commit, dispatch }, data) {
      request("get", constants.retrieveUserCacheOnDB(data.reviewer))
        .then((response) => {
          commit("MUTATE", {
            type: "retrievedCacheApplicationInformation",
            data: response.data.data,
          });
          dispatch("setLocalStorageCachedRecord", data.applicationData);
        })
        .catch((err) => {
          console.error(err);
          error("We had difficulty retrieving application cached data");
        });
    },
    persistingUserCacheOnDB({ commit, dispatch }, payload) {
      request("post", constants.storeUserCache, payload)
        .then((response) => {
          dispatch("setLocalStorageCachedRecord", response.data.data);
        })
        .catch((e) => {
          console.error(e);
          commit("MUTATE", { type: "loading" }, false);
          error("We experienced an error caching your action");
          commit("SET_ALERT", {
            status: "err",
            message: "We experienced an error caching your action",
          });
        });
    },
    setGrantListTableItems({ commit }, data) {
      commit("MUTATE", { type: "grantListTableItems", data: data });
    },
  },
};
