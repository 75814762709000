import { isGrantReviewPortal } from "@/environment";

export default {
  to: "/dashboard/application-list",
  order: 2,
  icon: "mdi-account-details",
  title: "Applications",
  disabled: JSON.parse(isGrantReviewPortal) ? true : false,
  sublinks: [
    {
      to: `/dashboard/application/list`,
      order: 1,
      title: "My Applications",
      disabled: false,
      icon: "mdi-format-list-numbered",
    },
    {
      to: `/dashboard/application/reports`,
      order: 2,
      title: "Reports",
      disabled: false,
      icon: "mdi-file-chart",
    },
  ],
};
