<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container fluid>
    <v-dialog v-model="dialog" persistent scrollable max-width="1000">
      <v-card>
        <v-toolbar>
          <v-btn @click="dialog = false" dark color="black" class="mr-4">
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
          {{ title }}
        </v-toolbar>
        <v-card-text v-if="documents">
          <v-data-table
            :headers="headers"
            :items="documents"
            :loading="documentLoading"
            loading-text="Loading... Please stand by"
          >
            <!--eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.actions="{ item }">
              <v-btn
                @click="viewDocument(item)"
                class="my-2 mx-2"
                color="green"
                v-if="item.fileExtension === 'pdf'"
              >
                <v-icon class="mr-2">mdi-eye</v-icon>
                View
              </v-btn>
              <v-btn
                @click="downloadDocument(item)"
                class="my-2 mx-2"
                color="blue"
              >
                <v-icon class="mr-2">mdi-download</v-icon>
                Download
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <viewDocument
      :dialog="viewDoc"
      :doc="documentToView"
      @modDialog="closeViewDocdialog"
    />
  </v-container>
</template>
<script>
import viewDocument from "./ViewDocuments.vue";
import { FileMixin } from "@/mixins";

export default {
  name: "ApplicationAttachmentDialog",
  props: {
    documents: {
      required: true,
    },
    attachmentDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    loader: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    viewDocument,
  },
  mixins: [FileMixin],
  data() {
    return {
      documentLoaded: [false, false],
      viewDoc: false,
      documentToView: {},
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Attachment Name",
          value: "fileName",
          sortable: true,
        },
        {
          text: "Attachment Type",
          value: "fileExtension",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ];
    },
    dialog: {
      get() {
        return this.attachmentDialog;
      },
      set(val) {
        this.$emit("closeAttachmentDialog", val);
      },
    },
    documentLoading() {
      return this.loader;
    },
  },
  methods: {
    closeViewDocdialog(val) {
      this.viewDoc = val;
    },

    viewDocument(item) {
      this.documentToView = item;
      this.viewDoc = true;
    },
    decodeBase64(base64) {
      return window.atob(base64);
    },
    getPdfUrl(document) {
      return URL.createObjectURL(
        new Blob([this.decodeBase64(document)], { type: "application/pdf" })
      );
    },
    pageCount: function (value) {
      const bin = atob(value);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
  },
};
</script>
