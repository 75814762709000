import { render, staticRenderFns } from "./boardPaper.vue?vue&type=template&id=ca8b49dc&scoped=true&"
import script from "./boardPaper.vue?vue&type=script&lang=js&"
export * from "./boardPaper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca8b49dc",
  null
  
)

export default component.exports