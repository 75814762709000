// import fileType from "file-type";
//
export default {
  methods: {
    downloadDocument(item) {
      const byteCharacters = atob(item.attachment);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: `application/${item.fileExtension}`,
      });

      // Create a temporary download link and trigger the download
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${item.fileName}.${item.fileExtension}`;
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
  },
};
