<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <v-card>
      <v-card-title class="text-h6 text-capitalize">
        {{ params.title }}
      </v-card-title>
      <v-card-text>
        {{ params.text }}
      </v-card-text>
      <v-card-text>
        <v-switch
          v-if="params.hideSwitch"
          v-model="HideFromApplicant"
          :label="
            HideFromApplicant ? 'Hide from applicant' : 'Show to applicant'
          "
          inset
        />
        <v-textarea
          outlined
          v-if="params.input"
          v-model="input"
          :placeholder="params.label"
        />
        <v-autocomplete
          v-if="params.isList"
          v-model="listInput"
          ref="autcomplete"
          :items="params.items"
          :placeholder="params.listLabel"
          :item-value="(item) => item.Code"
          :item-text="(item) => item.Description"
          :rules="[() => !hasErrors || 'This field is required']"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="cancel">
          {{ params.rejectLabel || "No" }}
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="confirm"
          :disabled="validateCategory"
        >
          {{ params.acceptLabel || "Yes" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "../../utils";

export default {
  name: "appConfirmation",
  data: function () {
    return {
      dialog: false,
      params: {
        title: "",
        text: "",
        input: false,
        label: "",
        isList: false,
        listInput: "",
        listLabel: "",
        items: [],
        rejectLabel: "",
        acceptLabel: "",
        onConfirm: {},
        onCancel: {},
        isCommentsMandatory: true,
        hideSwitch: true,
      },
      input: "",
      HideFromApplicant: false,
      hasErrors: false,
    };
  },

  mounted() {
    console.log(this.params.items);
    eventBus.$on("show", (params) => {
      this.show(params);
    });
  },
  computed: {
    validateCategory() {
      return this.params.listInput === "" ? true : false;
    },
  },
  methods: {
    show: function (params) {
      this.params = params;
      this.dialog = true;
    },

    hide: function () {
      this.dialog = false;
    },

    confirm: function () {
      if (typeof this.params.onConfirm === "function") {
        if (
          this.params.input === true &&
          this.input === "" &&
          this.params.isCommentsMandatory
        ) {
          this.hasErrors = true;
        } else {
          this.hasErrors = false;
          this.params.onConfirm(
            this.input,
            this.listInput,
            this.HideFromApplicant
          );
          this.input = "";
          this.hide();
        }
      } else {
        this.hide();
        this.input = "";
      }
    },

    cancel: function () {
      if (typeof this.params.onCancel === "function") {
        this.params.onCancel();
        this.hide();
        this.input = "";
      } else {
        this.hide();
        this.input = "";
      }
    },
  },
};
</script>

<style scoped></style>
