<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  beforeMount() {
    this.$store.dispatch("Landing/getCompanyInformation");
    this.$store.dispatch("Landing/getCompanies");
  },
};
</script>
