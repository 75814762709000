<template>
  <v-container class="px-5">
    <v-card class="mt-5" min-height="70vh">
      <v-card-title> Application Reports </v-card-title>

      <v-divider />

      <v-container class="pa-10">
        <v-row>
          <v-col cols="2" v-for="(report, i) in reportForms" :key="i">
            <v-card
              outlined
              hover
              class="text-center"
              @click="openReport(report)"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex flex-row justify-center align-center"
                  >
                    <h1 class="text-h2">
                      <v-icon size="80">
                        {{ report.data().icon || "mdi-file-chart" }}
                      </v-icon>
                    </h1>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text class="caption text-center">
                {{ report.data().title || `Report ${i + 1}` }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog v-model="dialog" width="350">
      <component :is="loadedForm" v-bind="{ toggleDialog: toggleDialog }" />
    </v-dialog>
  </v-container>
</template>

<script>
import * as forms from "./forms";

export default {
  name: "applicationReport",
  data: function () {
    return {
      loading: false,
      dialog: false,
      formTitle: "",
      loadedForm: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Setup/getSubscriptionPeriods");
      v.$store.dispatch("Setup/getMemberTypes");
    });
  },

  computed: {
    subscriptionPeriods() {
      return this.$store.getters["Setup/subscriptionPeriod"];
    },

    memberTypes() {
      return this.$store.getters["Setup/memberType"];
    },

    reportForms() {
      let f = [];
      Object.keys(forms).map((k) => {
        f.push(forms[k]);
      });
      return f;
    },
  },

  methods: {
    openReport: function (report) {
      this.loadedForm = report;
      this.dialog = true;
    },

    toggleDialog: function () {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped></style>
