<template>
  <v-dialog v-model="setDialog" persistent scrollable fullscreen>
    <v-row justify="center">
      <v-btn
        @click="setDialog = false"
        color="red"
        class="mt-4"
        style="position: absolute; top: 0%; right: 12%"
        ><v-icon class="mr-2">mdi-export</v-icon>
        close
      </v-btn>
      <v-col cols="12" class="pdf-viewer">
        <iframe
          :src="`data:application/pdf;base64,${doc.attachment}`"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
export default {
  name: "ViewDocument",
  props: {
    dialog: {
      default: () => false,
      required: true,
      type: Boolean,
    },
    doc: {
      default: () => {},
      type: Object,
      required: true,
    },
  },
  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("modDialog", value);
      },
    },
  },
};
</script>
<style scoped>
.pdf-viewer {
  width: 100;
  height: auto; /* Set your desired height */
  margin: 0 auto;
}
iframe {
  /* width: 50%; */
  height: 100vh;
}
.align-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.d-flex {
  display: flex;
}
</style>
