<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container fluid class="parent-container">
    <v-card v-if="application" flat>
      <v-card class="mb-4" color="white" dark flat>
        <v-row>
          <v-col cols="12">
            <v-card-title style="color: black" v-if="application">
              {{ application.title }}
            </v-card-title>
          </v-col>
          <v-col cols="12" md="6" sm="4" lg="12">
            <div class="button-holder-container">
              <div class="applicationBtns">
                <v-btn
                  color="black"
                  outlined
                  plain
                  @click="banckToApplications"
                >
                  <v-icon class="mr-2"> mdi-arrow-left</v-icon>
                  BACK TO LIST</v-btn
                >
                <v-btn color="blue" outlined plain @click="callForConcept">
                  <v-icon class="mr-2">mdi-file</v-icon>view applications call
                </v-btn>
                <v-btn color="red" outlined plain @click="rejectReview">
                  <v-icon class="mr-2">mdi-cancel</v-icon>
                  Reject
                </v-btn>
                <v-btn
                  color="brown"
                  outlined
                  plain
                  @click="openViewer(application.no)"
                >
                  <v-icon class="mr-2">mdi-paperclip</v-icon>
                  Attachments
                </v-btn>
                <v-btn
                  color="orange"
                  outlined
                  plain
                  @click="declareConflictOfinterest"
                >
                  <v-icon class="mr-2">mdi-alert</v-icon>declare conflict here
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  plain
                  @click="submitReviewedApplication"
                >
                  <v-icon class="mr-2">mdi-check-circle</v-icon>
                  SUBMIT
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col>
          <v-card class="px-4" flat v-if="application">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-card-title> Application Sections </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="application.applicationSections"
                  :items-per-page="10"
                  item-key="no"
                  ref="dataTable"
                >
                  <template v-slot:item.rating="{ item }" v-if="reviewerScore">
                    <v-card-text>
                      {{ getMarks(item.code) }}
                    </v-card-text>
                  </template>
                  <template
                    v-slot:item.reviewed="{ item }"
                    v-if="reviewerScore"
                  >
                    <v-icon :color="getIcon(item.code).color">
                      {{ getIcon(item.code).icon }}
                    </v-icon>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      class="ratebtn"
                      color="accent"
                      smal
                      @click="openSectionDialog(item)"
                    >
                      Rate this section
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
              <div class="count-down-container">
                <span>Submit in</span>
                <div
                  id="refreshCountDown"
                  :key="refreshKey"
                  class="countDownDisplay"
                >
                  <span
                    v-for="(item, i) in Object.keys(countdown)"
                    :key="i"
                    class="px-2"
                  >
                    <span class="font-weight-bold text-h4">
                      {{ countdown[item] }}
                    </span>
                    <sub class="ml-n1">
                      {{
                        item === "days"
                          ? "d"
                          : item === "hours"
                          ? "h"
                          : item === "minutes"
                          ? "m"
                          : item === "seconds"
                          ? "s"
                          : ""
                      }}
                    </sub>
                  </span>
                </div>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-toolbar class="mb-4" color="white" dark elevation="1">
        <v-btn @click="banckToApplications">
          <v-icon class="mr-2"> mdi-arrow-left</v-icon>
          BACK
        </v-btn>
        <v-card-title style="color: black" v-if="application">
          {{ application.title }}
        </v-card-title>

        <v-spacer></v-spacer>
        <!-- <v-btn color="brown" class="mr-3" elevation="0">
          <v-icon class="mr-2">mdi-paperclip</v-icon>
          Attachments
        </v-btn> -->
        <v-btn color="orange" elevation="0" disabled>
          <v-icon class="mr-2">mdi-alert</v-icon>
          Declare conflict of interest?
        </v-btn>
        <v-btn class="ml-3" color="green" elevation="0" disabled>
          <v-icon class="mr-2">mdi-check-circle</v-icon>
          SUBMIT
        </v-btn>
      </v-toolbar>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <GrantApplicationSection
      :dialog="sectionDialog"
      :sectionLines="sectionLines"
      :savedSectionReview="sectionReview"
      :mark="mark"
      :documents="applicationLineAttachment"
      @updateMark="setMark"
      :comments="comments"
      @updateComment="setcomment"
      @setDialog="twikSectionDialog"
      @save="saveReview"
      :loader="loading"
    />
    <ConflictOfInterestDialog
      :dialog="conflictOfinterest"
      :comment="declareConflictComment"
      @closeConflictOfInterestDialog="closeConflictOfInterestDialog"
      @submitComment="submitDeclarationOfConflict"
    />
    <template v-if="application">
      <ApplicationAttachmentDialog
        :title="application.title"
        :documents="applicationAttachment"
        :loader="loading"
        :attachmentDialog="attachmentsDialog"
        @closeAttachmentDialog="ClosingAttachmentDialog"
      />
    </template>
  </v-container>
</template>
<script>
import GrantApplicationSection from "./GrantApplicationSection.vue";
import ConflictOfInterestDialog from "./ConflictOfInterestDialog.vue";
import RouterMixin from "../../../router/RouterMixin";
import ApplicationAttachmentDialog from "./ApplicationAttachmentDialog.vue";
import { appName } from "@/environment";
import GrantMixin from "../GrantMixin";

export default {
  name: "GrantApplicationCard",
  components: {
    GrantApplicationSection,
    ConflictOfInterestDialog,
    ApplicationAttachmentDialog,
  },
  mixins: [RouterMixin, GrantMixin],
  data() {
    return {
      refreshKey: 0,
      countdown: {},
      sectionReview: {},
      sectionDialog: false,
      conflictOfinterest: false,
      sectionLines: {},
      // eslint-disable-next-line vue/no-computed-properties-in-data
      comments: "",
      declareConflictComment: "",
      mark: null,
      sectionCode: "",
      attachmentsDialog: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Grants/getApplication", {
        no: v.decodeRoute(v.$route.params.applicationId),
      });
    });
  },
  mounted() {
    // Initial calculation
    this.updateCountdown();

    // Update the countdown every 1 second
    setInterval(() => {
      this.updateCountdown();
      this.refreshKey++;
    }, 1000);
  },
  computed: {
    headers() {
      return [
        {
          text: "Reviewed?",
          value: "reviewed",
          align: " start",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          align: " start",
          sortable: false,
        },
        {
          text: "Rating",
          value: "rating",
          align: " start",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          align: " start",
          sortable: false,
        },
      ];
    },
    error() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "error",
      });
    },
    loading() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "loading",
      });
    },
    application() {
      // return this.appSections[0];
      return this.$store.getters["Grants/grantGetters"]({
        type: "application",
      })[0];
    },
    assignedApplication() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "assignedApplication",
      });
    },
    authUser() {
      return JSON.parse(localStorage.getItem(`${appName}_user`) || "[]");
    },
    errorOnSubmit() {
      return this.$store.getters["Grants/grantGetters"]({ type: "error" });
    },
    reviewerScore() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "reviewerScores",
      });
    },
    applicationAttachment() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "applicationAttachments",
      });
    },
    applicationLineAttachment() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "lineAttachments",
      });
    },
  },
  methods: {
    rejectReview() {
      const payload = {
        documentNo: this.application.no,
        reviewer: this.authUser.code,
        reviewStage: this.application.reviewStage,
        reject: true,
      };
      this.reviewRejection(payload);
    },
    callForConcept() {
      const url = `${
        process.env.VUE_APP_GRANT_APPLICATION_URL
      }${this.encodeRoute(this.application.grant)}`;
      const newTab = window.open(url, "_blank");
      newTab.focus();
    },
    updateCountdown() {
      if (this.application && this.assignedApplication) {
        const reviewDeadline = this.assignedApplication.find((app) => {
          return app.documentNo === this.application.no;
        });
        const dday = Date.parse(reviewDeadline.reviewTo) - Date.now();
        this.countdown.days = Math.floor(dday / (24 * 60 * 60 * 1000));
        this.countdown.hours = Math.floor((dday / (60 * 60 * 1000)) % 24);
        this.countdown.minutes = Math.floor((dday / (1000 * 60)) % 60);
        this.countdown.seconds = Math.floor((dday / 1000) % 60);
      }
    },
    ClosingAttachmentDialog(val) {
      this.attachmentsDialog = val;
    },
    openViewer(code) {
      this.$store.dispatch("Grants/getApplicationAttachments", { no: code });
      this.attachmentsDialog = !this.attachmentsDialog;
    },
    setMark(val) {
      this.mark = val;
    },
    setcomment(val) {
      this.comments = val;
    },
    getIcon(code) {
      const sectionScore = this.reviewerScore.find(
        (score) => score.section === code
      );

      if (sectionScore) {
        return { icon: "mdi-check-circle", color: "success" };
      }
      return { icon: "mdi-minus-circle", color: "" };
    },
    getMarks(code) {
      const sectionScore = this.reviewerScore.find(
        (score) => score.section === code
      );
      return sectionScore ? sectionScore.marks : 0;
    },
    banckToApplications() {
      this.$router.push({
        name: "grantList",
      });
    },
    declareConflictOfinterest() {
      this.conflictOfinterest = !this.conflictOfinterest;
    },
    openSectionDialog(item) {
      this.sectionReview = this.reviewerScore.find((sectionScore) => {
        return sectionScore.section === item.code;
      });
      console.log("Open section:", this.sectionReview);
      // this.sectionCode = item.code;
      this.sectionDialog = true;
      // this.$store.dispatch("Grants/reviewerScore", {
      //   documentNo: this.application.no,
      //   reviewer: this.authUser.code,
      // });
      const section = this.application.applicationSections.filter(
        (section) => section.code === item.code
      );
      this.sectionLines = section[0];
      this.$store.dispatch("Grants/getLineAttachments", {
        no: this.decodeRoute(this.$route.params.applicationId),
        lineNo: parseInt(this.sectionLines.code),
      });
    },
    twikSectionDialog() {
      this.sectionDialog = !this.sectionDialog;
      this.comments = "";
      this.mark = null;
    },
    closeConflictOfInterestDialog(setDialog) {
      this.conflictOfinterest = setDialog;
    },
    submitDeclarationOfConflict(comment) {
      const payload = {
        conflictOfInterestComment: comment,
        documentNo: this.application.no,
        reviewStage: this.application.reviewStage,
        reviewer: this.authUser.code,
        conflictOfInterest: true,
      };
      this.$store.dispatch("Grants/declareConflict", payload);
      this.$router.push({
        name: "grantList",
      });
    },
    saveReview(obj) {
      const payload = {
        documentNo: this.application.no,
        reviewer: this.authUser.code,
        ...obj,
        marks: Number(obj.marks),
      };
      const alreadyReviewed = this.reviewerScore.find((scoredSection) => {
        return scoredSection.section === obj.section;
      });
      console.log(alreadyReviewed);
      if (alreadyReviewed) {
        const newPayload = {
          ...payload,
          reviewStage: this.application.reviewStage,
        };
        this.$store.dispatch("Grants/updateMarks", newPayload);
        return;
      }
      this.$store.dispatch("Grants/saveMarks", payload);
      if (this.error === null && !this.loading) {
        localStorage.removeItem(
          `${this.decodeRoute(this.$route.params.applicationId)}.${
            this.sectionLines.code
          }`
        );
      }
      // this.$store.dispatch("Grants/reviewerScore", {
      //   documentNo: this.application.no,
      //   reviewer: this.authUser.code,
      // });
    },
    submitReviewedApplication() {
      const payload = {
        documentNo: this.application.no,
        reviewStage: this.application.reviewStage,
        reviewer: this.authUser.code,
        submitted: true,
      };
      this.$store.dispatch("Grants/submitReviewedApplication", payload);
      setTimeout(() => {
        this.errorOnSubmit
          ? null
          : this.$router.push({
              name: "grantList",
            });
      }, 3000);
    },
  },
  watch: {
    application() {
      this.$store.dispatch("Grants/reviewerScore", {
        documentNo: this.application.no,
        reviewer: this.authUser.code,
      });
    },
    countdown(oldVal, newVal) {
      this.countdown = newVal;
      if (newVal) {
        console.log(newVal);
        this.countdown = newVal;
      }
    },
  },
};
</script>
<style scoped>
html {
  font-size: 100%;
}
.applicationBtns {
  padding-left: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: right;
  width: max-content;
}
.button-holder-container {
  display: flex;
  justify-content: right;
}
.applicationBtns > * {
  width: max-content;
}
.parent-container {
  position: relative;
}
.count-down-container {
  position: absolute;
  left: 0rem;
  top: -12.4rem;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 12rem;
  font-size: 24px; /* Custom styles */
  background-color: transparent; /* Custom background color */
}
.count-down-container > * {
  display: block;
}
.countDownDisplay {
  display: grid;
  grid-template-columns: max-content max-content;
}
/* @media (min-resolution: 144dpi) {
  .count-down-container {
    top: -24.6rem;
    right: -2.2rem;
    width: 12rem;
    height: 12rem;
  }
}
@media (min-resolution: 96dpi) {
  .count-down-container {
    top: -26.4rem;
    right: -1.6rem;
    width: 8rem;
    height: 6rem;
    border-radius: 1%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  } */
/* } */
</style>
