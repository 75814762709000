import { request } from "@/service";
import setupConstants from "@/modules/setup/setupConstants";

export default {
  namespaced: true,

  state: {
    stages: [],
    memberType: [],
    subscriptionPeriod: [],
    reviewers: [],
  },

  mutations: {
    SET_MEMBER_TYPES: (state, payload) => {
      state.memberType = payload;
    },

    SET_SUBSCRIPTION_PERIOD: (state, payload) => {
      state.subscriptionPeriod = payload;
    },

    SET_REVIEWERS: (state, payload) => {
      state.reviewers = payload;
    },

    SET_STAGES: (state, payload) => {
      state.stages = payload;
    },
  },

  getters: {
    subscriptionPeriod: (state) => state.subscriptionPeriod,
    memberType: (state) => state.memberType,
    reviewers: (state) => state.reviewers,
    stages: (state) => state.stages,
  },

  actions: {
    getMemberTypes({ commit }) {
      request("get", setupConstants.memberType)
        .then((res) => {
          commit("SET_MEMBER_TYPES", res.data.data);
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getSubscriptionPeriods({ commit }) {
      request("get", setupConstants.subscriptionPeriod)
        .then((res) => {
          commit("SET_SUBSCRIPTION_PERIOD", res.data.data);
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getReviewers({ commit }) {
      request("get", setupConstants.reviewers)
        .then((res) => {
          commit("SET_REVIEWERS", res.data.data);
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getStages({ commit }) {
      request("get", setupConstants.stages)
        .then((res) => {
          commit("SET_STAGES", res.data.data);
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
