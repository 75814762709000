<template>
  <v-card outlined :loading="loading">
    <v-card-title> Board Paper </v-card-title>

    <v-divider />

    <v-card-text class="pa-10">
      <v-form ref="boardForm">
        <v-select
          dense
          outlined
          :items="memberTypes"
          v-model="formData.memberCategory"
          item-text="Description"
          item-value="Code"
          label="Membership Type"
          :rules="rules.required"
        />
        <v-select
          dense
          outlined
          v-model="formData.subscriptionPeriod"
          :items="subscriptionPeriods"
          item-text="Description"
          item-value="Code"
          label="Subscription Period"
          :rules="rules.required"
        />
      </v-form>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn text @click="toggleDialog"> Cancel</v-btn>

      <v-btn color="primary" @click="submit"> View</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AuthService from "@/modules/auth/AuthService.js";
import validationMixin from "@/mixins/ValidationMixin";
import { eventBus } from "@/utils";

export default {
  name: "boardPaper",
  mixins: [validationMixin],
  props: ["toggleDialog"],
  data: function () {
    return {
      formData: {
        memberCategory: "",
        subscriptionPeriod: "",
      },
      loading: false,
      title: "Board Paper",
    };
  },

  computed: {
    subscriptionPeriods() {
      return this.$store.getters["Setup/subscriptionPeriod"];
    },

    memberTypes() {
      return this.$store.getters["Setup/memberType"];
    },
  },

  methods: {
    submit: function () {
      if (!this.$refs.boardForm.validate()) return;

      this.loading = true;
      this.formData.createdBy = AuthService.user.code;
      this.$store
        .dispatch("Application/getBoardPaper", this.formData)
        .then((res) => {
          eventBus.$emit("open-viewer", res.data.data.board_paper_url);
          this.loading = false;
          this.toggleDialog();
        });
    },
  },
};
</script>

<style scoped></style>
