<template>
  <v-container fluid>
    <v-container>
      <v-app-bar color="transparent" flat>
        <v-btn
          active-class="primary"
          text
          :to="{ name: 'Application Summary' }"
        >
          {{
            isGrantReviewPortal
              ? "Grants application Summary"
              : "Application Summary"
          }}
        </v-btn>

        <v-spacer />

        <v-autocomplete
          label="Filter By Reviewer"
          v-model="reviewerFilter"
          :items="reviewers"
          :item-text="(item) => item.names"
          :item-value="(item) => item.code"
          dense
          style="width: 100px"
          class="mt-2"
          placeholder="Select Reviewer"
          @change="getStats"
          v-if="!isGrantReviewPortal"
        />

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile elevation="0" v-bind="attrs" v-on="on">
              Filter By {{ filterBy }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class="text-capitalize"
              v-for="(filter, index) in filters"
              :key="index"
              @click="changeDateFilter(filter)"
            >
              <v-list-item-title>{{ filter }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn elevation="0" tile @click="dialog = true">
          {{ currFilterText }}
        </v-btn>
      </v-app-bar>
    </v-container>

    <v-dialog v-model="dialog" width="500">
      <v-card rounded flat>
        <v-card-text class="pa-0">
          <v-date-picker
            full-width
            v-if="filterBy === 'Day'"
            v-model="dateFrom"
            :range="range"
            color="primary"
          />

          <v-date-picker
            full-width
            v-if="filterBy === 'Month'"
            v-model="dateFrom"
            :range="range"
            type="month"
            color="primary"
          />

          <v-card-text class="pa-10" v-if="filterBy === 'Year'">
            <v-select
              full-width
              dense
              outlined
              v-model="dateFrom"
              :items="subscriptionPeriods"
              item-text="Code"
              item-value="Code"
              label="Year"
              color="primary"
              class=""
            />
          </v-card-text>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-switch
            v-if="filterBy === 'Day' || filterBy === 'Month'"
            v-model="range"
            label="Range"
            color="primary"
          />
          <v-spacer />
          <v-btn text @click="dialog = false"> Close </v-btn>
          <v-btn
            :disabled="!dateFrom && dateFrom.length === 0"
            color="primary"
            @click="getStats"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <router-view />
    </v-container>
  </v-container>
</template>

<script>
import { DateMixin } from "@/mixins";
import moment from "moment/moment";
import { isArray } from "lodash";
import { AuthService } from "@/modules/auth";
import { isGrantReviewPortal } from "@/environment";

export default {
  name: "dashboardSummaries",
  mixins: [DateMixin],

  data: function () {
    return {
      currFilter: 3,
      range: false,
      dialog: false,
      filterBy: "Day",
      dateFrom: "",
      dateTo: "",
      reviewerFilter: null,
      filters: ["Day", "Month", "Year"],
      filterDates: [],
      isGrantReviewPortal: JSON.parse(isGrantReviewPortal),
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.dateFrom = moment().format("YYYY-MM-DD");
      v.dateTo = moment().format("YYYY-MM-DD");

      if (isGrantReviewPortal !== "true") {
        v.$store.dispatch("Setup/getSubscriptionPeriods");
        v.$store.dispatch("Dashboard/getReviewLogs");
        v.$store.dispatch("Setup/getReviewers");
        v.getStats();
      }
    });
  },
  computed: {
    user() {
      return AuthService.user;
    },
    reviewers() {
      return this.$store.getters["Setup/reviewers"];
    },
    currFilterText() {
      if (!this.dateFrom) return "Select Date";

      switch (this.filterBy) {
        case "Day":
          return isArray(this.dateFrom)
            ? moment(this.dateFrom[0]).format("YYYY-MM-DD") +
                " - " +
                moment(this.dateFrom[1]).format("YYYY-MM-DD")
            : moment(this.dateFrom).format("YYYY-MM-DD");
        case "Month":
          return isArray(this.dateFrom)
            ? this.dateFrom[0] + " - " + this.dateFrom[1]
            : this.dateFrom;
        case "Year":
          return this.dateFrom;
      }

      return "Select Date";
    },

    subscriptionPeriods() {
      return this.$store.getters["Setup/subscriptionPeriod"];
    },

    reviewLogs() {
      return this.$store.getters["Dashboard/reviewLogs"];
    },
  },

  methods: {
    getStats: function () {
      let payload = {
        documentType: this.$route.meta.name,
        fromDate: null,
        toDate: null,
      };

      let period = null;

      switch (this.filterBy) {
        case "Day":
          this.dateTo = this.dateFrom;
          payload.fromDate = this.dateFrom;
          payload.toDate = this.dateFrom;

          if (this.range) {
            payload.fromDate = this.dateFrom[0];
            payload.toDate = this.dateFrom[1];
          }

          break;

        case "Month":
          payload.fromDate = moment(this.dateFrom + "-01")
            .startOf("month")
            .format("YYYY-MM-DD");
          payload.toDate = moment(this.dateFrom + "-01")
            .endOf("month")
            .format("YYYY-MM-DD");

          if (this.range) {
            payload.fromDate = moment(this.dateFrom[0] + "-01")
              .startOf("month")
              .format("YYYY-MM-DD");
            payload.toDate = moment(this.dateFrom[1] + "-01")
              .endOf("month")
              .format("YYYY-MM-DD");
          }
          break;

        case "Year":
          period = this.subscriptionPeriods
            .filter((p) => {
              return p.Code === this.dateFrom;
            })
            .shift();

          console.log(period);

          payload.fromDate = period.StartDate;
          payload.toDate = period.EndDate;
          break;
      }

      payload.reviewer = this.reviewerFilter
        ? this.reviewerFilter
        : this.user.code;

      this.$store.dispatch("Dashboard/getDateStatistics", payload);
      this.$store.dispatch("Dashboard/getReviewStats", payload);

      this.dialog = false;
      this.range = false;
    },

    changeDateFilter: function (type) {
      this.filterBy = type;
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
