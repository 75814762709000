export default {
  grants: (params) => `grant/review/grant?${getParams(params)}`,
  application: (params) => `grant/review/application?${getParams(params)}`,
  conflict: "grant/review/conflict",
  saveMarks: "grant/review/saveMarks",
  editMarks: (params) => `grant/review/edit?${getParams(params)}`,
  updateMarks: "grant/review/editMarks",
  reviewerScore: (params) => `grant/review/reviewerScore?${getParams(params)}`,
  submitReviewedApplication: "grant/review/submit",
  grantApplicationAttachments: (params) =>
    `grant/review/attachments?${getParams(params)}`,
  lineAttachments: (params) =>
    `grant/review/line_attachments?${getParams(params)}`,
  reject: "grant/review/reject",
  uploadAfterOfflineReview: "grant/review/importReviewDoc",
  downloadForOfflineReview: (params) =>
    `grant/review/exportReviewDoc?${getParams(params)}`,
  storeUserCache: "grant/support/store_cache",
  retrieveUserCacheOnDB: (params) =>
    `grant/support/get_cache?${getParams(params)}`,
  deleteCachedRecordFromDB: "grant/support/delete_cache",
};

function getParams(obj) {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&")
    .toString();
}
