<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container fluid>
    <v-flex>
      <v-card>
        <v-card-title>
          Grant Application List
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search for application number"
            class="font-weight-medium"
            append-icon="mdi-magnify"
            @input="customeSearchTextFilter"
            single-line
            hide-details
          >
          </v-text-field>
        </v-card-title>
        <div class="grantListContainer">
          <div class="groupings">
            <div
              class="groupings-tab"
              v-for="group in grantListGroupings"
              :key="group.id"
              :data-id="group.id"
              @click="setActiveTab($event, group)"
            >
              {{ group.text }}
            </div>
          </div>
          <div :key="dataTableKey">
            <v-data-table
              :headers="conditionalHeaders"
              :items="applicationsToReview"
              :items-per-page="10"
              item-key="no"
              :search="search"
              :loading="loading"
              loading-text="Loading grant applications... Please wait"
              :sort-by="['no', 'grant', 'title']"
              :sort-desc="[true, true, false]"
              multi-sort
            >
              <template v-slot:item.submitted="{ item }">
                <v-card-text>
                  {{ item.submitted ? "Yes" : "No" }}
                </v-card-text>
              </template>
              <template v-slot:item.action="{ item }">
                <v-row>
                  <v-col cols="12" md="6" sm="4" lg="12">
                    <v-btn
                      color="primary"
                      class="my-2 py-3 mx-2 white--text"
                      :loading="loading"
                      :disabled="loading"
                      small
                      @click="reviewApplication(item)"
                    >
                      <v-icon class="mr-2">mdi-eye</v-icon>
                      Review</v-btn
                    >
                    <v-btn
                      class="red mb-2 py-3 mx-2 white--text"
                      :loading="loading"
                      :disabled="loading"
                      small
                      @click="rejectApplication(item)"
                    >
                      <v-icon class="mr-2">mdi-cancel</v-icon>
                      Reject</v-btn
                    >
                    <v-btn
                      class="orange mb-2 py-3 mx-2 white--text"
                      :loading="loading"
                      :disabled="loading"
                      small
                      @click="uploadApplicationAfterReviewedOffline(item)"
                      v-if="item.documentDownloaded && !item.documentUploaded"
                    >
                      <v-icon class="mr-2">mdi-file-upload</v-icon>
                      upload
                    </v-btn>
                    <v-btn
                      class="blue mb-2 py-3 mx-2 white--text"
                      :loading="loading"
                      :disabled="loading"
                      small
                      @click="downloadApplicationToReviewOffline(item)"
                      v-if="!item.documentDownloaded && !item.documentUploaded"
                    >
                      <v-icon class="mr-2">mdi-export</v-icon>
                      download</v-btn
                    >
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </v-flex>
    <v-dialog v-model="reviewedApplicationDialog" persistent max-width="500">
      <v-card class="py-3 px-3">
        <v-file-input
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          label="Upload your review application"
          v-model="excelUpload"
          @change="$emit('getBase64Equivalent')"
        >
        </v-file-input>
        <v-card-actions>
          <v-btn @click="$emit('review-upload-dialog-unset')">close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getUploadedReviewedDocument"
            >upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { appName } from "@/environment";
import RouterMixin from "../../../router/RouterMixin";
import GrantMixin from "../GrantMixin";
import { FileMixin } from "@/mixins";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "GrantsList",
  mixins: [RouterMixin, GrantMixin, FileMixin],
  data() {
    return {
      setActiveUpload: {},
      excelUpload: null,
      reviewedApplicationUploadData: null,
      reviewedApplicationDialog: false,
      dataTableKey: uuidv4(),
      search: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      const user = JSON.parse(localStorage.getItem(`${appName}_user`) || "[]");
      v.$store.dispatch("Grants/getGrantsToReview", { code: user.code });
      v.$store.dispatch("Grants/getLocalStorageCachedRecord", {
        reviewer: { reviewer: user.code },
        applicationData: {},
      });
    });
  },
  mounted() {
    if (
      this.$route.params.cue !== undefined &&
      this.$route.params.cue === "inprogress"
    ) {
      this.$store.dispatch("Grants/applicationReviewInprogress", {
        reviewer: this.user.code,
      });
    }
    this.$store.dispatch("Grants/getLocalStorageCachedRecord", {
      reviewer: { reviewer: this.user.code },
      applicationData: {},
    });
    this.$on("set-upload-interface", () => {
      this.reviewedApplicationDialog = true;
    });
    this.$on("review-upload-dialog-unset", () => {
      this.reviewedApplicationDialog = false;
    });
    this.$on("getBase64Equivalent", () => {
      const reader = new FileReader();
      reader.onload = () => {
        this.reviewedApplicationUploadData = reader.result;
      };
      reader.readAsDataURL(this.excelUpload);
    });
  },
  computed: {
    headers() {
      return [
        {
          text: "Application No.",
          value: "documentNo",
          align: " start",
          sortable: true,
        },
        {
          text: "Grant Title",
          value: "grantTitle",
          align: " start",
          sortable: true,
        },
        {
          text: "Review Status",
          value: "reviewStage",
          align: " start",
          sortable: false,
        },
        {
          text: "Submitted",
          value: "submitted",
          align: " start",
          sortable: false,
        },
        {
          text: "Awarded Marks",
          value: "marks",
          align: " start",
          sortable: true,
        },
        {
          text: "Action",
          value: "action",
          align: " start",
          sortable: false,
        },
      ];
    },
    conditionalHeaders() {
      if (this.$route.params.cue === "submitted") {
        return this.headers.filter((columns) => {
          return columns.value !== "action";
        });
      }
      return this.headers;
    },
    grantListGroupings() {
      if (this.grantApplications) {
        const uniqueSet = new Set();
        this.grantApplications.forEach((application) => {
          const title = application["grantTitle"];
          if (!uniqueSet.has(title)) {
            uniqueSet.add(title);
          }
        });
        const groupings = [...uniqueSet];

        return groupings.map((group) => {
          return {
            text: group,
            id: uuidv4(),
          };
        });
      }
      return [];
    },
    grantApplications() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "assignedApplication",
      });
    },
    applicationsUnderReviewInprogress() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "assignedApplicationInprogress",
      });
    },
    loading() {
      return this.$store.getters["Grants/grantGetters"]({ type: "loading" });
    },
    user() {
      return JSON.parse(localStorage.getItem(`${appName}_user`) || "[]");
    },
    applicationsToReview() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "grantListTableItems",
      });
    },
    getApplicationDownloadedForOfflineReview() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "applicationForOfflineReview",
      });
    },
    cachedDownloadInformation() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "retrievedCacheApplicationInformation",
      });
    },
    getActiveDownload() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "activeDownload",
      });
    },
  },
  methods: {
    setActiveTab(e, item) {
      this.setApplicationToview(
        this.$route.params.cue ? this.$route.params.cue : "assigned",
        item.text
      );
      this.dataTableKey = item.id;
      const getCurrentActiveTab = document.querySelector(".active-tab");
      if (
        getCurrentActiveTab.getAttribute("data-id") !==
        e.target.getAttribute("data-id")
      ) {
        getCurrentActiveTab.classList.remove("active-tab");
        e.target.classList.add("active-tab");
      }
      this.$store.dispatch("Grants/getLocalStorageCachedRecord", {
        reviewer: { reviewer: this.user.code },
        applicationData: {},
      });
    },
    getUploadedReviewedDocument() {
      if (this.reviewedApplicationUploadData) {
        const item = (this.reviewedApplicationUploadData =
          this.reviewedApplicationUploadData.split(",")[1]);
        this.uploadOfflineReviewedApplication(item);
        this.$emit("review-upload-dialog-unset");
      }
    },
    uploadApplicationAfterReviewedOffline(item) {
      this.$emit("set-upload-interface");
      this.setActiveUpload = item;
    },
    uploadOfflineReviewedApplication(item) {
      const data = {
        documentNo: this.setActiveUpload.documentNo,
        reviewer: this.user.code,
        b64File: item,
      };
      this.$store.dispatch("Grants/uploadApplicationToReviewOffline", data);
    },
    downloadApplicationToReviewOffline(item) {
      const data = {
        documentNo: item.documentNo,
        documentName: item.grantTitle,
        reviewer: this.user.code,
      };
      this.$store.dispatch("Grants/downloadApplicationToReviewOffline", data);
    },
    setApplicationToview(cue, key) {
      if (cue === "inprogress") {
        //GETTING RESPONSE FROM THE API WITH THE REVIEWS IN PROGRESS
        //THE RESPONSE IS AN INDEXED OBJECT VALUEs OBJECT
        let newList = this.applicationsUnderReviewInprogress;

        //CONVERT THE OBJECT TO ARRAY:
        //THEN FLATTEN IT TO BE A ONE DIMENTIONAL ARRAY
        newList = Object.entries(newList).flat();

        //REMOVING NON-OBJECT VALUES FROM THE RESULTANT ARRAY:
        newList = newList.filter((value) => {
          return typeof value === "object";
        });

        //REMOVING SUBMITTED GRANT REVIEWS FROM THE LIST:
        newList = newList.filter((item) => {
          return item.submitted !== true;
        });
        this.$store.dispatch("Grants/setGrantListTableItems", newList);
        return newList;
      }
      if (cue === "submitted") {
        const newList = this.grantApplications.filter((application) => {
          return (
            application.conflictOfInterest !== true &&
            application.submitted === true &&
            application.grantTitle === key
          );
        });
        this.$store.dispatch("Grants/setGrantListTableItems", newList);
        return newList;
      }
      const newList = this.grantApplications.filter((application) => {
        return (
          application.conflictOfInterest !== true &&
          application.submitted !== true &&
          application.grantTitle === key
        );
      });
      this.$store.dispatch("Grants/setGrantListTableItems", newList);
      return newList;
    },
    customeSearchTextFilter() {
      const searchedApplication = this.grants.filter((grant) => {
        return (
          grant.no.toString().toUpperCase() ===
            this.search.toString().toUpperCase ||
          grant.grant.toString().toUppercase() ===
            this.search.toString().toUpperCase
        );
      });
      return searchedApplication.length === 0
        ? this.grants
        : (this.grants = searchedApplication);
    },
    rejectReview(item) {
      const payload = {
        documentNo: item.documentNo,
        reviewer: this.user.code,
        rejected: true,
      };
      this.reviewRejection(payload);
    },
    reviewApplication(item) {
      // this.$store.dispatch("Grants/getApplication", {
      //   no: item.documentNo,
      // });
      // this.$store.dispatch("Grants/reviewerScore", {
      //   documentNo: item.documentNo,
      //   reviewer: this.user.code,
      // });
      this.$router.push({
        name: "reviewSingleApplication",
        params: { applicationId: this.encodeRoute(item.documentNo) },
      });
    },
  },
  watch: {
    grantListGroupings(newValue) {
      if (newValue) {
        this.setApplicationToview(
          this.$route.params.cue ? this.$route.params.cue : "assigned",
          newValue[0].text
        );
        const groupings = document.querySelector(".groupings");
        setTimeout(() => {
          const firstChild = groupings.firstElementChild;
          firstChild.classList.add("active-tab");
        }, 1000);
      }
    },
    applicationsUnderReviewInprogress(oldVal, newVal) {
      if (oldVal.document !== newVal) {
        this.setApplicationToview("inprogress");
      }
    },
    cachedDownloadInformation(newValue) {
      if (newValue) {
        const newList = this.applicationsToReview.map((app) => {
          const objectItem = newValue.find((item) => {
            return item.documentNo === app.documentNo;
          });
          if (objectItem && Object.entries(objectItem).length > 0) {
            app = {
              ...app,
              documentDownloaded: objectItem.applicationDownloaded,
              documentUploaded: objectItem.applicationUploaded,
            };
          } else {
            app = {
              ...app,
              documentDownloaded: false,
              documentUploaded: false,
            };
          }
          return app;
        });
        this.$store.dispatch("Grants/setGrantListTableItems", newList);
      }
    },
    getApplicationDownloadedForOfflineReview(oldValue, newValue) {
      if (oldValue !== newValue) {
        if (this.cachedDownloadInformation.length > 0) {
          if (this.getActiveDownload.length > 0) {
            const getItemToDownload = this.cachedDownloadInformation.find(
              (item) => {
                return item.documentNo === this.getActiveDownload;
              }
            );
            if (getItemToDownload) {
              const item = {
                fileName: `${getItemToDownload.documentName}${getItemToDownload.documentNo}${getItemToDownload.reviewer}`,
                attachment: newValue,
                fileExtension: "xlsx",
              };
              this.downloadDocument(item);
              const newList = this.applicationsToReview.map((app) => {
                if (app.documentNo === getItemToDownload.documentNo) {
                  app = {
                    ...app,
                    documentDownloaded: getItemToDownload.applicationDownloaded,
                    documentUploaded: getItemToDownload.applicationUploaded,
                  };
                } else {
                  if (app.documentDownloaded && app.documentUploaded)
                    return app;
                  else {
                    app = {
                      ...app,
                      documentDownloaded: false,
                      documentUploaded: false,
                    };
                  }
                }
                return app;
              });
              this.$store.dispatch("Grants/setGrantListTableItems", newList);
              this.$store.dispatch(
                "Grants/persistingUserCacheOnDB",
                getItemToDownload
              );
            }
          }
        }
      }
    },
  },
};
</script>
<style scoped>
html {
  font-size: 100%;
}
.grantListContainer {
  display: grid;
  grid-template-columns: 3fr 9fr;
}
.groupings {
  cursor: pointer;
  padding: 1.6rem;
  border: 0.1rem solid rgba(128, 128, 128, 0.12);
  border-radius: 0.4rem;
  box-shadow: 0.1rem 0.1rem 0.2rem 0.4rem rgba(0, 0, 0, 0.046);
}
.groupings-tab {
  margin: 1.6rem 0;
}
.active-tab {
  transition: all 0.2s ease-in-out;
  border-bottom: 0.1rem solid #43a047;
  width: max-content;
  max-width: 20rem;
  background-color: #43a047;
  color: #fff;
  padding: 0.4rem 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0.1rem 0.1rem 0.2rem 0.4rem rgba(0, 0, 0, 0.013);
}
@media (max-width: 48em) {
  .grantListContainer {
    display: grid;
    grid-template-rows: auto;
  }
}
</style>
