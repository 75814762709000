<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.smAndDown ? '99vw' : '50vw'"
    fullscreen
    dark
  >
    <v-card tile :loading="urls.length === 0">
      <v-card-title>
        File Viewer ({{ urls.length }})
        <v-spacer />

        <v-btn icon @click="closeViewer">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="mb-5"
        v-for="(url, i) in urls.filter((u) => u)"
        :key="i"
        style="height: auto"
      >
        <v-row>
          <v-col cols="8" offset="2">
            <v-card outlined>
              <v-card-title>
                File {{ i + 1 }}

                <v-spacer />

                <v-btn
                  icon
                  :href="url"
                  :download="url.substr(url.lastIndexOf('/') + 1)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text class="pa-0">
                <vue-pdf-embed
                  v-if="['pdf'].includes(getFileExtension(url))"
                  ref="pdfRef"
                  :source="url"
                  style="width: 100%"
                />

                <v-img v-else contain :src="url" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn @click="closeViewer"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "@/utils";
import vuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import _ from "lodash";

export default {
  name: "appViewer",
  components: { vuePdfEmbed },

  data: function () {
    return {
      urls: [],
      dialog: false,
      pageCount: 0,
    };
  },

  mounted() {
    eventBus.$on("open-viewer", (urls) => {
      this.urls = _.isString(urls) ? [urls] : urls;
      this.dialog = true;
      this.$forceUpdate();
    });
  },

  methods: {
    getFileExtension(path) {
      return path ? path.substr(path.lastIndexOf(".") + 1) : null;
    },

    closeViewer: function () {
      this.urls = [];
      this.dialog = false;
    },

    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },

    handlePasswordRequest(callback, retry) {
      callback(prompt(retry ? "Enter password again" : "Enter password"));
    },
  },
};
</script>

<style scoped></style>
